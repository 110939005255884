<template>
  <v-snackbar
    v-model="message.view"
    :color="message.color"
    :timeout="message.timeout"
    location="top"
  >
    <span style="white-space:pre-wrap;">{{ message.text }}</span>
    <template v-slot:actions>
      <v-btn variant="text" @click="message.view = false"> 閉じる </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  computed: {
    message() {
      return this.$store.state.message;
    },
  },
};
</script>
<style scoped></style>
