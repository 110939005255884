// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify, ThemeDefinition } from "vuetify";

const myCustomLightTheme = {
  dark: false,
  colors: {
    primary: "#8B090B",
    "primary-lighten-5": "#F2E9EB",
    secondary: "#424242",
    error: "#B00020",
    info: "#3B358C",
    success: "#AFB42B",
    warning: "#E64A19",
  },
};

export default createVuetify({
  theme: {
    defaultTheme: "myCustomLightTheme",
    themes: {
      myCustomLightTheme,
    },
  },
});
