import axios from "axios";
import store from "../store";

//ログイン してるかどうかの確認する機能
export function checkAuth() {
  return new Promise((resolve) => {
    if (store.state.auth.authrole) {
      //vuexに保存してるユーザの情報があればログイン済み
      resolve(true);
    } else {
      axios
        .get("/api/user")
        .then((response) => {
          //vuexに保存してない場合はAPIで確認する
          if (response.status == 200 && response.data != 0) {
            //認証処理
            store.commit("auth/setAuthUser", {
              authuser: response.data,
              authrole: response.data.is_admin,
            });
            resolve(true);
          }
        })
        .catch(function (error) {
          resolve(false);
          console.log(error);
        });
    }
  });
}

//権限があるるかどうかの確認する機能
export function checkAuthByRole(role) {
  //ルートに設定した権限にログイン ユーザのものがあるか確認
  return new Promise((resolve) => {
    if (role.includes(store.state.auth.authrole)) {
      resolve(true);
    } else {
      resolve(false);
    }
  });
}

export function checkEnabled() {
  return new Promise((resolve) => {
    if (store.state.auth.authuser.is_enabled) {
      resolve(true);
    } else {
      resolve(false);
    }
  });
}
