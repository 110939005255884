<template>
  <router-view />
</template>

<script>
export default {
  methods: {
    createTitleDesc: function (routeInstance) {
      //titleを設定する処理
      if (routeInstance.meta.title) {
        var setTitle = routeInstance.meta.title + " | JUSTICE契約状況調査 ONLINE";
        document.title = setTitle;
      } else {
        document.title =
          "JUSTICE契約状況調査 ONLINE";
      }
    },
  },
  mounted: function () {
    var routeInstance = this.$route;
    this.$axios.get("/api/config/app_state").then((response) => {
        this.$store.commit("env/setAppState", {
          app_state: response.data,
        });
    });
    this.createTitleDesc(routeInstance);
  },
  watch: {
    $route(routeInstance) {
      this.createTitleDesc(routeInstance);
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;600&display=swap");
#app {
  font-family: "Noto Sans JP", Helvetica, Arial, sans-serif;
  font-weight: 400;
  text-align: left;
  color: #000;
}

nav {
  padding: 20px;
  a {
    font-weight: bold;
    color: #2c3e50;
  }
}

a.uline {
  color: #3b358c;
  &:hover {
    text-decoration: underline;
  }
}
.page_content {
  padding: 20px 20px 80px 20px;
}
.v-table.bordered > .v-table__wrapper > table {
  tr > td {
    /* 改行対策 */
    white-space: pre-wrap;
  }
  & > thead > tr > th {
    background-color: #f2e9eb;
    font-size: 13px;
    font-weight: bold;
    border: 1px solid #ccc;
  }
  & > tbody > tr {
    & > th {
      background-color: #f2e9eb;
      font-size: 14px;
      font-weight: bold;
      border: 1px solid #ccc;
    }
    & > td {
      padding: 10px;
      font-size: 13px;
      border: 1px solid #ccc;
    }
  }
  .required::after {
    content: "※必須";
    color: #bd0000;
    font-size: 12px;
    margin-left: 1em;
  }
}
.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #a71d2a !important;
}

.pankuzu {
  font-size: 13px;
  margin-bottom: 40px;
  & > span {
    &:after {
      content: ">";
      margin: 0 5px;
    }
    &:last-child:after {
      content: "";
    }
  }
}

/*form*/
.v-text-field input.v-field__input {
  font-size: 14px !important;
}

.notice-dialog {
  white-space: pre-line;
}

#app .header-default {
  background-color: #004D40 !important;
  color:white;
}
#app .header-demo {
  background-color: rgb(234, 85, 80) !important;
  color:white !important;
}
</style>
