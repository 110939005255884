<template>
  <v-layout>
    <Header />
    <SnackBar />
    <v-main>
      <div class="page_content">
        <router-view></router-view>
      </div>
    </v-main>
  </v-layout>
</template>

<script>
import Header from "@/components/Header";
import SnackBar from "@/components/SnackBar";

export default {
  name: "Index",
  components: { Header, SnackBar },
};
</script>

<style></style>
