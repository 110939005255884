import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueScrollTo from "vue-scrollto";
import { loadFonts } from "./plugins/webfontloader";
import axios from "axios";
import store from "./store";

loadFonts();
const axiosInstance = axios.create({
  withCredentials: true,
});
const app = createApp(App)
  .use(router)
  .use(vuetify)
  .use(VueScrollTo, { offset: -80 });
app.use(store);
app.config.globalProperties.$axios = { ...axiosInstance };

app.mount("#app");

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // 認証エラー時の処理
    if (error.response.status === 401 || error.response.status === 419) {
      store.commit("auth/updateLoginState", {
        loggedout: true,
      });
      router.push("/login");
    } else if (error.response.status === 421) {
      store.commit("setMessage", {
        text: "メール送信機能の不調により一時的に処理が実行できませんでした。数時間後に再度お試しください。",
        color: "error",
      });
    } else if (error.response.status === 500) {
      store.commit("setMessage", {
        text: "システムエラーが発生しました。リロード後にもう一度試していただき、改善しなければ管理者へご連絡ください。",
        color: "error",
      });
    } else if (error.response.status === 429) {
      store.commit("setMessage", {
        text: "通信回数制限に達しました。1分後にもう一度試してください。",
        color: "warning",
      });
    } else if (error.response.status === 403) {
      store.commit("setMessage", {
        text: "権限がありません",
        color: "warning",
      });
      router.push("/");
    }

    return Promise.reject(error);
  }
);
