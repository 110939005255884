<template>
  <v-app-bar :class="'header-'+appState" image="/images/header.jpg">
    <template v-slot:image v-if="appState!='demo'">
      <v-img
        gradient="to top right, rgba(139,9,11,.8), rgba(171,25,28,.8)"
      ></v-img>
    </template>

    <template v-slot:prepend>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" icon="mdi-arrow-collapse-left" v-if="drawer" title="サイドメニューを折りたたみます"></v-app-bar-nav-icon>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" icon="mdi-arrow-expand-right" v-else title="サイドメニューを表示します"></v-app-bar-nav-icon>
    </template>

    <v-app-bar-title  style="flex:0 0 auto !important;">
      <router-link to="/" class="text-white" style="text-decoration: none">
        <div class="d-flex justify-start align-center">
          <div style="width: 160px">
            <v-img src="/logo.jpg"></v-img>
          </div>
          <span class="ml-3 text-body-1 font-weight-bold">JUSTICE契約状況調査 ONLINE</span>
        </div>
      </router-link>
    </v-app-bar-title>

    <v-spacer></v-spacer>

    <div>
      <span class="mr-2 text-caption">{{ this.$store.state.auth.authuser.library_name }}</span>
      <span style="font-weight: 500">{{ this.$store.state.auth.authuser.name }}</span>
    </div>
    <div>
      <v-dialog max-width="500">
        <template v-slot:activator="{ props: activatorProps }">
          <span class="text-caption">
            【<span
              class="text-decoration-underline"
              style="cursor: pointer"
              v-bind="activatorProps"
              >問合せ先</span
            >】
          </span>
        </template>

        <template v-slot:default="{ isActive }">
          <v-card>
            <v-card-title>
              <h5 class="font-weight-bold">契約状況調査に関する問合せ先</h5>
            </v-card-title>
            <v-card-text>
              <div class="text--primary">
                JUSTICE調査作業部会・JUSTICE事務局<br />
                justice-cs@nii.ac.jp
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text="閉じる" @click="isActive.value = false"></v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </div>
    <v-menu v-model="usermenu" :close-on-content-click="true" location="bottom">
      <template v-slot:activator="{ props }">
        <v-btn
          style="border: 1px solid #fff"
          size="small"
          color="white"
          class="ml-4"
          v-bind="props"
        >
          <v-icon class="mr-2">mdi mdi-menu</v-icon>
          Menu
        </v-btn>
      </template>

      <v-card min-width="300">
        <v-list>
          <v-list-item :title="this.$store.state.auth.authuser.name" :subtitle="this.$store.state.auth.authuser.library_name">
            <template v-slot:prepend>
              <v-avatar color="red-accent-1">
                <v-icon color="white">mdi-account</v-icon>
              </v-avatar>
            </template>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item to="/change_settings">個人設定変更</v-list-item>
          <v-list-item to="/change_password">パスワード変更</v-list-item>
        </v-list>
      </v-card>
    </v-menu>
    <v-btn
      @click="logoutDialog = true"
      size="small"
      variant="flat"
      color="white"
      light
      class="mx-3"
      >ログアウト</v-btn
    >
    <v-dialog v-model="logoutDialog" width="500px">
      <v-card>
        <v-card-text class="text-center mb-5">
          ログアウトしてよろしいですか？</v-card-text
        >

        <div class="d-flex justify-center pb-5">
          <v-btn
            color="secondary"
            variant="outlined"
            @click="logoutDialog = false"
            class="mr-3"
            >キャンセル</v-btn
          >
          <v-btn color="primary" @click="logout">ログアウト</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-app-bar>
  <v-navigation-drawer
    permanent
    theme="light"
    v-model="drawer"
    style="background-color: #f2f2f2"
  >
    <v-list density="compact" nav>
      <v-list-item
        v-for="item in menulist"
        :prepend-icon="item.icon"
        :title="item.title"
        :to="item.to"
        :disabled="item.disabled"
        exact
      ></v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "HeaderTeacher",

  data() {
    return {
      drawer: true,
      usermenu: false,
      logoutDialog: false,
      appState: this.$store.state.env.app_state,
      menulist: [
        {
          title: "ダッシュボード",
          to: "/",
          icon: "mdi-view-dashboard",
        },
        {
          title: "調査票回答",
          to: "/surveys",
          icon: "mdi-ballot-outline",
        },
        {
          title: "組織管理",
          to: "/library",
          icon: "mdi-library-shelves",
        },
        {
          title: "FAQ",
          to: "/faqs",
          icon: "mdi-frequently-asked-questions",
        },
      ],
      user: {
        name: this.$store.state.auth.authuser.name,
        library: this.$store.state.auth.authuser.library_name,
      },
    };
  },
  methods: {
    logout: function () {
      this.$axios.get("/api/sanctum/csrf-cookie").then(() => {
        this.$axios
          .post("/api/logout")
          .then((response) => {
            if (response.data.success) {
              this.$store.commit("auth/resetAuthUser");
              window.location.href = "/login";
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
  },
};
</script>
